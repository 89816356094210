.home-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 100vh;
  font-family: 'Courier New', Courier, monospace;
  background-color: #f9f9f9;
}

.content-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 1200px;
}

.home-content {
  flex: 1;
  text-align: left;
  padding: 20px;
  margin-right: 20px;
}

.home-content h1 {
  font-size: 2.5rem;
  font-weight: bold;
  color: #333333;
  margin-bottom: 20px;
  text-align: center; /* Center the text */
}

.home-content h2 {
  font-size: 1.5rem;
  color: #333333;
  margin-bottom: 10px;
}

.home-content p {
  font-size: 1rem;
  margin-bottom: 15px;
  color: #555555;
}

.input-container {
  margin-top: 10px;
  text-align: center; /* Center the text */
  padding-bottom: 30px;
}

.input-container p {
  margin-bottom: 10px; /* Add some space below the text */
}

.input-container input[type="text"] {
  padding: 10px;
  font-size: 1rem;
  border: 1px solid #ccc;
  border-radius: 5px;
  margin-right: 10px;
  font-family: 'Lato', sans-serif;
}

.input-container button {
  padding: 10px 20px;
  font-size: 1rem;
  background-color: #45818e;
  color: #ffffff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  font-family: 'Lato', sans-serif;
}

.leaderboard-container {
  display: flex;
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
  height: 75vh;
  padding: 25px 0; /* 75px padding on top and bottom */
  box-sizing: border-box; /* Include padding in the element's total height */
}

.MuiTableContainer-root {
  max-height: 500px; /* Ensure the table container takes full height of parent */
  overflow-x: initial;
}

.MuiTable-root {
  background-color: #ffffff;
  border-radius: 8px;
  overflow: hidden;
  font-family: 'Lato', sans-serif;
  width: 100%;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  border-collapse: collapse; /* Ensure borders are collapsed */
}

.MuiTableCell-root {
  border-bottom: 1px solid #eee;
  border-right: 1px solid #ccc; /* Darker vertical lines */
  text-align: center;
  font-size: 0.9rem;
  padding: 10px;
  font-family: 'Lato', sans-serif;
}

.MuiTableCell-root:last-child {
  border-right: none; /* Remove the right border from the last cell */
}

.MuiTableHead-root {
  background-color: #d0e0e3;
  font-weight: bold;
  font-family: 'Lato', sans-serif;
}

.MuiTableRow-root {
  background-color: #ffffff;
  font-family: 'Lato', sans-serif;
}

.MuiTableRow-root:hover {
  background-color: #f1f1f1;
}

.MuiTableCell-head {
  position: sticky; /* Make the header sticky */
  top: 0; /* Stick to the top */
  background-color: #fff; /* Set background color to prevent overlap */
}

button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
  background-color: #808080; /* Change to gray */
  color: #ffffff; /* Ensure text is visible on gray background */
}
