.game-container {
  height: 100vh;
  display: flex;
}

.chat-container {
  flex: 3;
  display: flex;
  flex-direction: column;
  background-color: #f9f9f9;
  padding: 45px;
}

.game-title {
  text-align: center;
  margin: 0;
  padding: 20px 10px;
  background-color: #2c3e50;
  color: white;
  border-radius: 20px 20px 0 0;
  border: 1px solid #000000;
  border-bottom: none;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.25);
}

.chat-box {
  flex: 1;
  display: flex;
  flex-direction: column;
  border-radius: 0 0 20px 20px;
  border: 1px solid #000000;
  border-top: none;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.25);
}

.separator {
  border-top: 1px solid #ccc;
  margin: 10px 0;
  width: 100%;
}

.info-container {
  flex: 2;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  background-color: #f9f9f9;
}

.rules-section {
  padding: 10px;
  width: 100%;
  background-color: #f9f9f9;
  border-radius: 5px;
  font-family: 'Courier New', Courier, monospace, sans-serif;
}

.diagnostic-section {
  padding-bottom: 20px;
  width: 100%;
  background-color: #a2c4c9;
  border-radius: 20px;
  margin-bottom: 20px;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  border: 1px solid #252525;
}

.diagnostic-section h3 {
  text-align: center;
}

.diagnostic-table {
  width: 100%;
  border-collapse: collapse;
  justify-content: center;
}

.diagnostic-input {
  margin-left: 5px;
  margin-right: 5px;
  padding: 8px;
  font-size: 16px;
}

.centered-row {
  display: flex;
  justify-content: center;
}

.centered-body {
  display: flex;
  justify-content: center;
}

.submit-button {
  margin-top: 10px;
  padding: 15px 20px;
  width: 40%;
  background-color: #0b5394;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  border: 1px solid #ffffff;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.25);
}

.submit-button.disabled {
  background-color: #cccccc;
  cursor: not-allowed;
  box-shadow: none;
  border: none;
}

.navigation-buttons {
  display: flex;
  gap: 10px;
  width: 100%;
}

.nav-button {
  flex: 1;
  padding: 15px 20px;
  margin-left: 10px;
  margin-right: 10px;
  cursor: pointer;
  background-color: #f0f0f0;
  border: 1px solid #0b5394;
  border-radius: 5px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
}

.nav-button:last-child:not(.disabled) {
  background-color: #0b5394;
  color: white;
  border: 1px solid #ffffff;
  border-radius: 4px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.25);
}

.nav-button.disabled {
  background-color: #cccccc;
  cursor: not-allowed;
  border: none;
  box-shadow: none;
}

.chat-message-input {
  margin: 5px;
}
